export const formatNumber = (number: any) =>
  new Intl.NumberFormat().format(Number(number));

export const formatDate = (date: any, time: any) => {
  const updatedDate = String(date[0]).split(' ');
  return updatedDate[2] + ' ' + updatedDate[1] + ', ' + time;
};

export const toInteger = (string: string) => {
  return Math.floor(Number(String(string).replace(/,/g, '')));
};

// setAlert(`Maximum document size is 10 mb ${file.name}`);

// export const uploadFiles = async (
//   files: any,
//   uploadedFiles: any,
//   setDocuments: any,
//   documentsCount: any,
//   fileCategory: string,
// ) => {
//   for (let file of files) {
//     const body = new FormData();
//     body.append('file', file);
//     body.append('category', fileCategory);
//     const { uploadedFile, allDocuments } = await uploadDocument(body);
//     if (setDocuments) {
//       await uploadedFiles.push({
//         Key: uploadedFile[0].Key,
//         LastModified: Date.now(),
//       });
//       setDocuments(uploadedFiles);
//     }
//     store.dispatch(setUploadedDocumentsCountData(documentsCount.uploadedDocumentsCount + 1));
//     store.dispatch(setUserDocumentsCount(allDocuments.all.length));
//     store.dispatch(setUserDocuments(allDocuments.all));
//   }

//   return;
// };

export const moneyCommaSeperated = (amount: number | string) => {
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const roundUp = (amount: number) => {
  return Math.round((amount + Number.EPSILON) * 100) / 100;
};

export const capitalizeFirstLitter = (str: string) => {
  const arr = str.replace('_', ' ').toLocaleLowerCase().split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};

export const scrollElementIntoView = (
  element: HTMLElement,
  activeForm: any,
  behavior?: 'smooth' | 'instant' | 'auto',
) => {
  const scrollTop = window.pageYOffset || element.scrollTop;

  // Furthermore, if you have for example a header outside the iframe
  // you need to factor in its dimensions when calculating the position to scroll to
  const headerOutsideIframe = document.getElementById(activeForm).clientHeight;

  const finalOffset =
    element.getBoundingClientRect().top + scrollTop + headerOutsideIframe;

  window.parent.scrollTo({
    top: finalOffset,
    behavior: 'smooth',
  });
};

//random number between min (included) and max (not included):
export const generateRandomNumber = (min = 0, max = 2) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const cos = [
  {
    name: 'Arran Summerhill',
    position: 'Co-Founder',
    alt: 'Michael Hunter - Co-Founder - Holo Mortgage',
    profilePicture:
      'https://holo-www.s3.me-south-1.amazonaws.com/common/arran_agent_profile_picture.png',
  },
  {
    name: 'Michael Hunter',
    position: 'Co-Founder',
    alt: 'Michael Hunter - Co-Founder - Holo Mortgage',
    profilePicture:
      'https://holo-www.s3.me-south-1.amazonaws.com/common/michael_hunter_profile_picture.png',
  },
];

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return;
  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  return arr.join(' ');
};

export const normalizeEnum = (str: string, capitalize = true) => {
  if (!str) return '';
  const temp = str.replaceAll('_', ' ');
  return capitalize ? capitalizeFirstLetter(temp) : temp;
};

export const normalizeCamelCase = (text: string) => {
  if (!text) return;
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const enumToCamelCase = (str: string) => {
  return str.toLowerCase().replace(/_+(\w|$)/g, function ($$, $1) {
    return $1.toUpperCase();
  });
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const phoneToWhatsapp = (phoneInput: string) => {
  if (!phoneInput) return;
  let phone = phoneInput?.replace(/\s/g, '');
  //edge case for UAE
  if (phone.startsWith('05')) {
    phone = phone.slice(1);
  }
  return `https://wa.me/${phone}`;
};
export const isUUID = (str: string) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    str,
  );
};
export const convertToBoolean = (value: boolean | string): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'string') {
    return value.trim().toLowerCase() === 'true';
  }

  return false;
};
