import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export const productsListSlice = createSlice({
  name: 'productsList',
  initialState: {
    allProducts: [],
    fiveProducts: [],
    showAll: false,
  },
  reducers: {
    setProductsList: (state, action: PayloadAction<any>) => {
      state.allProducts = action.payload;
    },
    setShowAll: (state, action: PayloadAction<any>) => {
      state.showAll = action.payload;
    },
    setFiveProducts: (state, action: PayloadAction<any>) => {
      state.fiveProducts = action.payload;
    },
  },
});

export const { setProductsList, setFiveProducts, setShowAll } =
  productsListSlice.actions;

export default productsListSlice.reducer;
