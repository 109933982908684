import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { MortgageApplicationFormCategory } from '../enums/mortgageApplicationFormCategory';

const initialState = {
  status: '',
  stepsList: [],
  mortgageApplicationFormCategory: MortgageApplicationFormCategory.LOAN_TYPE,
};
export const applicationProgressBarSlice = createSlice({
  name: 'applicationProgressBar',
  initialState,
  reducers: {
    setApplicationProgressBar: (state, actions) => {
      state.stepsList = [...actions.payload.stepsList];
      state.status = actions.payload.status;
    },

    setApplicationActiveCategory: (
      state,
      action: PayloadAction<{
        mortgageApplicationFormCategory: MortgageApplicationFormCategory;
      }>,
    ) => {
      state.mortgageApplicationFormCategory =
        action.payload.mortgageApplicationFormCategory;
    },
  },
});

export const { setApplicationProgressBar, setApplicationActiveCategory } =
  applicationProgressBarSlice.actions;

export default applicationProgressBarSlice.reducer;
