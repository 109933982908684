import { createSlice } from '@reduxjs/toolkit';

export const applicationLoanAmountSlice = createSlice({
  name: 'applicationLoanAmount',
  initialState: {
    applicationLoanAmount: 25,
  },
  reducers: {
    setApplicationLoanAmount: (state, actions) => {
      return {
        ...state,
        applicationLoanAmount: actions.payload,
      };
    },
  },
});

export const { setApplicationLoanAmount } = applicationLoanAmountSlice.actions;

export default applicationLoanAmountSlice.reducer;
