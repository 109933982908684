import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

export const theme = extendTheme({
  fonts: {
    heading: 'Lexend, sans-serif',
    body: 'Lexend,Raleway, sans-serif',
  },
  styles: {
    global: () => ({
      body: {
        bg: { base: '#F5F6F6', md: 'white', lg: 'black' },
      },
    }),
  },
  components: {
    Steps,
  },
});
