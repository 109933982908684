import { ApplicationStepEnum } from '@/src/enums/application-step.enum';
import { HubspotDealStageEnum } from '@/src/types/application';

export const isServer = typeof window === 'undefined';

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const removeNullishValues = <T>(object: T): Partial<T> =>
  Object.fromEntries(
    Object.entries(object).filter(
      ([_key, value]) => value !== undefined && value !== '',
    ),
  ) as T;

export const handleHubspotDealStageEnum = (
  stage: HubspotDealStageEnum,
): ApplicationStepEnum => {
  if (!stage) return ApplicationStepEnum.SELECT_PRODUCT;
  switch (stage) {
    case HubspotDealStageEnum.INBOUND_LEAD:
    case HubspotDealStageEnum.NO_ANSWER:
      return ApplicationStepEnum.CONFIRM_CHAT_DATE;

    case HubspotDealStageEnum.CONTACT_MADE:
    case HubspotDealStageEnum.PRODUCT_SELECTED:
    case HubspotDealStageEnum.FUTURE_CALL_BACK:
      return ApplicationStepEnum.SELECT_PRODUCT;

    case HubspotDealStageEnum.SUBMITTED_TO_LENDER:
      return ApplicationStepEnum.OBTAINING_PRE_APPROVAL;

    case HubspotDealStageEnum.PRE_APPROVAL_OBTAINED:
      return ApplicationStepEnum.SIGNING_MOU;

    case HubspotDealStageEnum.MOU_SIGNED:
    case HubspotDealStageEnum.VALUATION_INSTRUCTED:
      return ApplicationStepEnum.INSTRUCTING_VALUATION;

    case HubspotDealStageEnum.FINAL_OFFER_LETTER_ISSUED:
      return ApplicationStepEnum.ISSUING_FINAL_OFFER;

    case HubspotDealStageEnum.NOC_SELLERS_FINANCE_SETTLEMENT:
      return ApplicationStepEnum.SELLERS_FINANCE_SETTLEMENT;

    case HubspotDealStageEnum.TRANSFERRED_WON:
    case HubspotDealStageEnum.TRANSFERRED_WON_NO_REVIEW:
    case HubspotDealStageEnum.CLOSED_LOST:
    case HubspotDealStageEnum.THOMAS_COLUMN:
    case HubspotDealStageEnum.COMPLETED_CASES:
      return ApplicationStepEnum.FINISHED;

    default:
      return ApplicationStepEnum.SELECT_PRODUCT;
  }
};
