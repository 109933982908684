import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { dashboardApplication } from '@/src/types/application';

interface ProductsState {
  products: dashboardApplication[];
  productsToCompare: dashboardApplication[];
  tempSelectedProduct: dashboardApplication;
}

const initialState: ProductsState = {
  products: [],
  productsToCompare: [],
  tempSelectedProduct: null,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<dashboardApplication[]>) => {
      state.products = action.payload;
    },
    setProductsToCompare: (
      state,
      action: PayloadAction<dashboardApplication[]>,
    ) => {
      state.productsToCompare = action.payload;
    },
    setTempSelectedProduct: (
      state,
      action: PayloadAction<dashboardApplication>,
    ) => {
      state.tempSelectedProduct = action.payload;
    },
  },
});

export const { setProducts, setProductsToCompare, setTempSelectedProduct } =
  productsSlice.actions;

export default productsSlice.reducer;
