import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';

import { cn } from '@/src/util/tailwind';
import { isServer } from '../../util/helpers';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    if (!isServer) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (isServer) return;
    window.addEventListener('scroll', toggleVisible);
    return () => {
      // remove the listener
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <button
      onClick={() => scrollToTop()}
      className={cn(
        'fixed bottom-12 right-8 z-50 hidden h-12 w-12 cursor-pointer rounded-full border border-natural-gray-2 bg-white hover:bg-slate-200',
        { 'flex items-center justify-center': visible },
      )}
    >
      <FaArrowUp
        size={25}
        color="black"
        style={{
          display: visible ? 'inline' : 'none',
        }}
      />
    </button>
  );
};

export default ScrollButton;
