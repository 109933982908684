import { axiosInstance } from '@/src/util/axios';

export class PartnerService {
  static get = async (domain: string) => {
    try {
      const { data } = await axiosInstance.get(`/white-labels`, {
        params: { domain },
      });
      return data;
    } catch (error) {
      throw error;
    }
  };
}
