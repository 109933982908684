export const ignoreErrors = [
  /__show__deepen/,
  /Access is denied/,
  /anonymous function: captureException/,
  /Blocked a frame with origin/,
  /console is not defined/,
  /event is not defined/,
  /feedConf/,
  /MyIPhoneApp_ModifyShowModalDialog/,
  /vid_mate_check is not defined/,
  /win\.document\.body/,
  /window\._sharedData\.entry_data/,
  /ztePageScrollModule/,
  /__show__deepen/,
  // HOLO ADDED - START
  /CanceledError/,
  /attempted to hard navigate to the same URL/,
  /aborted/,
  /Non-Error promise rejection captured with value/,
  /ibFindAllVideos/,
  /querySelector/,
  /require is not defined/,
  /Failed to fetch/,
  /Cannot read properties of undefined (reading 'domInteractive')/,
  /Zone is not defined/,
  /The object can not be found here/,
  /failed to decode cache/,
  /Load failed/,
  //HOLO ADDED - END
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  'bmi_SafeAddOnload',
];

export const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];
