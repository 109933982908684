import React from 'react';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { AppStaticAssets } from './assetByDomain';
import { assetsByDomain } from './assetByDomain';
import { PartnerService } from '../services/partner/partnerService';
import { getSubDomainByUrl, isPartner } from '../util/location';

const AppStaticAssetsContext = createContext<AppStaticAssets>(null);

export const useAssetContext = () => {
  return useContext(AppStaticAssetsContext);
};

type AppStaticAssetsProviderProps = {
  children: ReactNode;
};

const AppStaticAssetsProvider = (props: AppStaticAssetsProviderProps) => {
  const { children } = props;

  const [assets, setAssets] = useState<AppStaticAssets>({
    ...assetsByDomain('useholo'),
    isDefault: true,
  });

  const getPartnerAssets = async (partner: string) => {
    let domainAssets: AppStaticAssets;
    try {
      domainAssets = await PartnerService.get(partner);
    } catch (error) {
      window.location.href = 'https://useholo.com';
    }

    return domainAssets;
  };

  const setAssetsByDomain = useCallback(async () => {
    let subDomain = getSubDomainByUrl(window.location.hostname);

    if (!isPartner(window.location.hostname)) {
      subDomain = 'useholo';
    }

    let assets: AppStaticAssets;
    const isPartnerDomain = subDomain !== 'useholo';

    if (isPartnerDomain) {
      assets = await getPartnerAssets(subDomain);
    } else {
      assets = assetsByDomain(subDomain);
    }
    setAssets({
      ...assets,
      isDefault: false,
      isPartnerDomain,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setAssetsByDomain();
    }
  }, [setAssetsByDomain]);

  return (
    <AppStaticAssetsContext.Provider value={assets}>
      {children}
    </AppStaticAssetsContext.Provider>
  );
};

export default AppStaticAssetsProvider;
