import { createSlice } from '@reduxjs/toolkit';

export const applicationMaxBorrowPercentageSlice = createSlice({
  name: 'applicationMaxBorrowPercentage',
  initialState: {
    applicationMaxBorrowPercentage: 75,
  },
  reducers: {
    setApplicationMaxBorrowPercentage: (state, actions) => {
      return {
        ...state,
        applicationMaxBorrowPercentage: actions.payload,
      };
    },
  },
});

export const { setApplicationMaxBorrowPercentage } =
  applicationMaxBorrowPercentageSlice.actions;

export default applicationMaxBorrowPercentageSlice.reducer;
