export enum ApplicationStepEnum {
  SELECT_PRODUCT = 'SELECT_PRODUCT',
  UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
  BOOK_A_CHAT = 'BOOK_A_CHAT',
  CONFIRM_CHAT_DATE = 'CONFIRM_CHAT_DATE',
  OBTAINING_PRE_APPROVAL = 'OBTAINING_PRE_APPROVAL',
  SIGNING_MOU = 'SIGNING_MOU',
  INSTRUCTING_VALUATION = 'INSTRUCTING_VALUATION',
  ISSUING_FINAL_OFFER = 'ISSUING_FINAL_OFFER',
  SELLERS_FINANCE_SETTLEMENT = 'SELLERS_FINANCE_SETTLEMENT',
  FINISHED = 'FINISHED',
}

export enum ApplicationStagesEnum {
  OBTAINING_PRE_APPROVAL = 'OBTAINING_PRE_APPROVAL',
  SIGNING_MOU = 'SIGNING_MOU',
  INSTRUCTING_VALUATION = 'INSTRUCTING_VALUATION',
  ISSUING_FINAL_OFFER = 'ISSUING_FINAL_OFFER',
  SELLERS_FINANCE_SETTLEMENT = 'SELLERS_FINANCE_SETTLEMENT',
}
