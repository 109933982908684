import type { PurchaseTypeEnum } from '@/src/enums/purchase-type.enum';
import type { ResidenceStatusEnum } from '@/src/enums/residence-status.enum';
import type { NationalityEnum } from '@/src/enums/nationality.enum';
import type { ApplicantTypeEnum } from '@/src/enums/applicant-type.enum';
import type { EmploymentStatusEnum } from '@/src/enums/employment-status.enum';
import type { Emirates } from '@/src/enums/emirates.enum';
import type { LoanTypeEnum } from '@/src/enums/loan-type.enum';
import type { MortgageTypeEnum } from '@/src/enums/mortgage-type.enum';
import type { Income } from '@/src/types/income';
import type { Loan } from '@/src/types/loan';
import type { Property } from '@/src/types/property';
import type { ApplicationStepEnum } from '@/src/enums/application-step.enum';
import type { User } from '@/src/types/user';
import type { MortgageApplicationFormCategory } from '../enums/mortgageApplicationFormCategory';

export type MortgageApplication = {
  purchaseType?: PurchaseTypeEnum;
  residenceStatus?: ResidenceStatusEnum;
  nationality: NationalityEnum;
  existingMortgage: string;
  applicantType?: ApplicantTypeEnum;
  employmentStatus?: EmploymentStatusEnum;
  employmentStatusA?: EmploymentStatusEnum;
  employmentStatusB?: EmploymentStatusEnum;
  monthlyIncome: string;
  monthlyIncomeA: string;
  monthlyIncomeB: string;
  age: string;
  ageA: string;
  ageB: string;
  foundProperty: string;
  propertyValue: string;
  loanToValue: string;
  outstandingLoan: string;
  currentLender: string;
  propertyLocation?: Emirates;
  propertyArea?: string;
  mortgageType?: MortgageTypeEnum;
  fixedRateTerm: string;
  loanType?: LoanTypeEnum;
  mortgageTerm: string;
  salaryTransfer: string;
  feeAssistance: string;
  name: string;
  email: string;
  phone: string;
  reference: string;
  provideFinancialAudits: string;
  provideFinancialAuditsA: string;
  provideFinancialAuditsB: string;
  partner: string;
};

export enum HubspotDealStageEnum {
  'INBOUND_LEAD' = '4903544',
  'NO_ANSWER' = '4903546',
  'CONTACT_MADE' = '4903545',
  'PRODUCT_SELECTED' = 'appointmentscheduled',
  'SUBMITTED_TO_LENDER' = 'qualifiedtobuy',
  'PRE_APPROVAL_OBTAINED' = 'presentationscheduled',
  'MOU_SIGNED' = 'decisionmakerboughtin',
  'VALUATION_INSTRUCTED' = 'contractsent',
  'FINAL_OFFER_LETTER_ISSUED' = 'closedwon',
  'NOC_SELLERS_FINANCE_SETTLEMENT' = 'closedlost',
  'TRANSFERRED_WON' = '843548',
  'TRANSFERRED_WON_NO_REVIEW' = '20600081',
  'FUTURE_CALL_BACK' = '13796275',
  'CLOSED_LOST' = '4878061',
  'THOMAS_COLUMN' = '25920860',
  'COMPLETED_CASES' = '53718820',
}

export interface country {
  value: number;
  label: string;
}

export interface phoneNumber {
  phone: string;
}

export interface referrer {
  id: string;
  text: string;
}

export const reference: referrer[] = [
  { id: 'google', text: 'Google' },
  { id: 'social_media', text: 'Social Medial' },
  { id: 'emailer', text: 'Emailer' },
  { id: 'word_of_mouth', text: 'Word of mouth' },
  { id: 'interoducer', text: 'Introducer' },
];

export const initState: MortgageApplication = {
  //@ts-ignore
  purchaseType: '',

  //@ts-ignore
  residenceStatus: '',

  //@ts-ignore
  nationality: '',
  existingMortgage: '',

  //@ts-ignore
  applicantType: '',

  //@ts-ignore
  employmentStatus: '',

  //@ts-ignore
  employmentStatusA: '',

  //@ts-ignore
  employmentStatusB: '',
  monthlyIncome: '',
  monthlyIncomeA: '',
  monthlyIncomeB: '',
  age: '',
  ageA: '',
  ageB: '',

  foundProperty: '',
  propertyValue: '',
  loanToValue: '',
  outstandingLoan: '',
  currentLender: '',
  lookForFinance: '', // ASK: Do we need this?

  //@ts-ignore
  propertyLocation: '',
  propertyArea: '',

  //@ts-ignore
  mortgageType: '',
  fixedRateTerm: '',

  //@ts-ignore
  loanType: '',
  mortgageTerm: '',
  salaryTransfer: '', // ASK: Do we need this?
  feeAssistance: '',
  name: '',
  email: '',
  phone: '',
  reference: '',
  provideFinancialAudits: '',
  provideFinancialAuditsA: '',
  provideFinancialAuditsB: '',
  password: '',
};

export interface ApplicationForm {
  attribute: string;
  header: string;
  category: MortgageApplicationFormCategory;
  paragraph?: string;
  needHelpHeader: string;
  needHelpContent: string;
  questionOne: string;
  questionTwo: string;
  questionThree?: string;
  input: string;
  checkbox: string;
  button: string;
  datePicker?: string;
  helpSection: string;
  calculation?: string;
  isActive: boolean;
  isVisible?: boolean;
  wasActive?: boolean;
}

export interface sliderCategory {
  pos: number;
  text: string;
}

export const journey: Array<sliderCategory> = [
  { pos: 2, text: 'Application submitted' },
  { pos: 20, text: 'Select a product' },
  { pos: 40, text: 'Documents uploaded' },
  { pos: 60, text: 'Call with Holo Expert' },
  { pos: 80, text: 'Submit to lender' },
];

export interface Item {
  label: string;
  value: string;
}

export interface dashboardApplication {
  id: string;
  emirate: string;
  initialRate: string;
  monthlyEmi: string;
  downPayment: string;
  dubaiLandDepartmentFee: string;
  mortgageRegistrationFee: string;
  trusteeFee: string;
  bankProcessingFee: string;
  bankProcessingFeeTwo: string;
  agencyFee: string;
  valuation: string;
  feesAddToLoan: string;
  totalUpfrontCost: string;
  productType: string;
  initialInterestRate: string;
  followOnRate: string;
  lifeInsurance: string;
  propertyInsurance: string;
  overpaymentsAllowedWithoutPenalty: string;
  MBP: string;
  bankId: number;
  loanPrefer: string;
  loanTerm: string;
  loanNeed: string;
  fixedTenor: string;
  applicantType: string;
  logo: string;
  propertyValue: string;
  mortgageReleaseFee: number;
}

export type Application = {
  id?: string;
  primaryUserId?: string;
  secondaryUserId?: string;
  nationality?: string;
  chatDate?: string;
  userSelectedBankProduct?: number | string;
  step?: ApplicationStepEnum;
  hsObjectId?: number;
  hubspotOwnerId?: number;
  conveyanceRequest?: boolean;
  isActive?: boolean;
  primaryUser?: User;
  income?: Income[];
  loan?: Loan;
  property?: Property;
  mortgageAgent: User;
  mortgageStatus: HubspotDealStageEnum;
};
