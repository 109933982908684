const ourSubDomains = [
  'www',
  'dev',
  'useholo',
  'useholo-www',
  '',
  'custom-holo',
];
export const getSubDomainByUrl = (url: string) => {
  return url.split('.').slice(0, 1).join('.');
};

export const isPartner = (url: string) => {
  const subDomain = getSubDomainByUrl(url);

  if (
    !subDomain ||
    ourSubDomains.includes(subDomain) ||
    subDomain.includes('localhost')
  ) {
    return false;
  }
  return true;
};
