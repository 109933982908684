import { configureStore } from '@reduxjs/toolkit';
import applicationProgressBarSlice from '../reducers/applicationProgressBar';
import applicationMaxBorrowPercentageReducer from '../reducers/applicationMaxBorrowPercentage';
import applicationLoanAmountReducer from '../reducers/applicationLoanAmount';
import productsListReducer from '../reducers/productsList';
import products from '../reducers/products';

export const store = configureStore({
  reducer: {
    products: products,
    productsList: productsListReducer,
    applicationProgressBar: applicationProgressBarSlice,
    applicationMaxBorrowPercentage: applicationMaxBorrowPercentageReducer,
    applicationLoanAmount: applicationLoanAmountReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
