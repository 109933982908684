import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import '@/components/style/style.scss';

import '@fontsource/lexend/300.css';
import '@fontsource/lexend/400.css';
import '@fontsource/lexend/500.css';
import '@fontsource/lexend/600.css';
import '@fontsource/lexend/700.css';
import '@fontsource/lexend';
import { QueryClientProvider } from 'react-query';
import { appWithTranslation } from 'next-i18next';
import { datadogRum } from '@datadog/browser-rum';
import AppStaticAssetsProvider from '@/src/context/staticAssetsProvider';
import { store } from '@/src/store';
import { queryClient } from '@/src/services/reactQueryClient';
import ScrollButton from '@/components/ScrollToTop';

import { theme } from '@/src/components/style/theme';
import '@/src/styles/global.scss';
import '@/src/components/common/Input/HoloPhoneInput/HoloPhoneInput.scss';

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const gtmId = process.env.NEXT_PUBLIC_GTM_ID || '';

    TagManager.initialize({ gtmId });
    hotjar.initialize(3801219, 6);

    if (process.env.NEXT_PUBLIC_ENV === 'production') {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATA_DOG_APPLICATION_ID,
        clientToken: process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: process.env.NEXT_PUBLIC_DATA_DOG_SERVICE,
        env: 'production',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
  }, []);

  return (
    <>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <AppStaticAssetsProvider>
              <Component {...pageProps} />
              <ScrollButton />
            </AppStaticAssetsProvider>
          </Provider>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
