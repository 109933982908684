export type AppStaticAssets = {
  formHeader: string;
  logo: string;
  color: string;
  cover?: string;
  isDefault?: boolean;
  domain: string;
  name: string;
  isPartnerDomain: boolean;
  setAssets: React.Dispatch<React.SetStateAction<AppStaticAssets>>;
  headerTextColor?: string;
};

export const assetsByDomain = (key: string): AppStaticAssets => {
  const currentAssets = {
    useholo: {
      color: 'black',
      cover: '',
      name: 'holo',
      domain: 'useholo',
      logo: '/assets/Holo_Logo_white.svg',
    },
  };
  return currentAssets[key];
};
