import type { AxiosError } from 'axios';
import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import { normalizeEnum } from '@/src/util/string';

const baseAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 1500000,
});

baseAxiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error && error?.code !== 'ERR_CANCELED') {
      SendErrorToSentry(error);
    }
    return Promise.reject(error);
  },
);

baseAxiosInstance.interceptors.request.use((req) => {
  const headers = {};

  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('token');
    const holo_token = localStorage.getItem('holo_token');

    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    if (holo_token) {
      const { userId } = parseJwt(holo_token);
      headers['Authorization'] = 'Bearer ' + holo_token;
      headers['userId'] = userId;
    }
  }

  req.headers = { ...req.headers, ...headers };
  return req;
});

export const axiosInstance = baseAxiosInstance;

const b64DecodeUnicode = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
      )
      .join(''),
  );

export const parseJwt = (token: string) =>
  JSON.parse(
    b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')),
  );

export const handleAxiosError = (
  error: AxiosError,
  defaultMsg = 'Oops Something Went Wrong!',
) => {
  if (!error) return;
  if (error?.code === 'ERR_CANCELED') return;

  const errorResponse = error?.response?.data as { [key: string]: unknown };

  let outputErrorMessage = '';
  if (
    errorResponse &&
    'errorCode' in errorResponse &&
    errorResponse.errorCode === 'VALIDATION_ERROR'
  ) {
    outputErrorMessage = 'Validation Error, ';

    (errorResponse.validationErrors as string[]).forEach((message) => {
      outputErrorMessage += ` ${normalizeEnum(message)}`;
    });
  } else {
    outputErrorMessage = String(errorResponse?.message || '');
  }

  return outputErrorMessage || defaultMsg;
};

const SendErrorToSentry = (error: AxiosError) => {
  if (!error) return;
  if (error?.code === 'ERR_CANCELED') return;
  Sentry.addBreadcrumb({
    category: 'axios',
    message: 'Summary',
    data: {
      url: error.request?.responseURL,
      body: error.response?.status + ' - ' + error.config?.data,
      response: error.request?.responseText,
    },
    type: 'error',
    level: 'error',
  });
  Sentry.addBreadcrumb({
    category: 'axios',
    message: 'All',
    data: error,
  });
  const errorMsg = error?.message + ' - ' + error.request?.responseURL;
  Sentry.captureException(new Error(errorMsg));
};
